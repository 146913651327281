import React from "react";
import Header from "@Components/Header/Header"
import JobListingPage from "@Components/JobListing/JobListing"
import { Link } from "gatsby";
import { Row, Col, Container, Modal } from 'react-bootstrap';
import FooterPage from "@Components/Footer/Footer"

const NotFoundPage = (props) => {
   return (
      <div className="wrapper slide">
         <div className="joblisting-page-header jobdetails-header job-search-header-block"><Header  nobanner={true} /></div>
         <div className="not-found-page">
           <Container>
         <h2>Page not found</h2>
<p>Sorry 😔 we couldn’t find what you were looking for</p>
         <Link className="btn btn-primary" to="/">Go home</Link>
         </Container>
         </div>
         <FooterPage />

      </div>
   )
};
export default NotFoundPage